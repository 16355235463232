/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

type EdgeTileProps = {
  tileWidth?: number;
  rotation?: number;
};

function EdgeTile(props: EdgeTileProps) {
  const { tileWidth = 30, rotation = 0 } = props;
  const transform = `rotate(${rotation * 90})`;
  return (
    <TileSVG
      width={tileWidth}
      height={tileWidth}
      viewBox="-15 -15 30 30"
      transform={transform}
    >
      <path className="bg" d="M -15, -15 V 15 H 14 V 14 H 15 V -15 Z" />
      <circle cx={5} cy={5} r={2} fill="hsla(0, 100%, 0%, 0.1)" />
    </TileSVG>
  );
}

export default EdgeTile;

const TileSVG = styled("svg")`
  .bg {
    fill: var(--col-bg-edge);
  }
`;
