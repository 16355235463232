/** @jsxImportSource @emotion/react */
import { useState } from "react";
import styled from "@emotion/styled";

import PipeTile, { PipePathDef, PipeTileDef } from "./PipeTile";
import PipeBoard from "./PipeBoard";

type TileData = {
  points: number[];
  rotation: number;
};
type TileState = Array<TileData | null>;

type PlayerHand = {
  tiles: TileState;
  active: number;
};

type PathSegment = {
  currentPath: {
    tileIdx: number;
    pathStart: number;
    pathEnd: number;
  } | null;
  nextTileIdx: number | null;
  nextTileEntry: number;
};

const StageContainer = styled("div")`
  width: 360px;
  height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: white;
  box-shadow: 0px 2px 4px hsla(0, 0%, 0%, 0.1), 0px 2px 2px hsla(0, 0%, 0%, 0.2);

  font-size: 16px;
`;

const Debug = styled("pre")`
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 12px;
  pointer-events: none;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const PauseOverlay = styled("div")`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: hsla(0, 100%, 100%, 0.8);
`;
const GameResults = styled("div")`
  position: absolute;
  right: 20px;
  bottom: 20px;
  left: 20px;
  box-shadow: 0px 2px 4px hsla(0, 0%, 0%, 0.1), 0px 2px 2px hsla(0, 0%, 0%, 0.2);

  padding: 16px 20px;
  background-color: #666;
  color: white;
  text-align: center;

  h1 {
    font-size: 24px;
    margin: 0;
    margin-bottom: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid hsla(0, 100%, 100%, 0.3);
  }
`;

const validValues = [0, 1, 2, 3, 4, 5, 6, 7];
function makePaths(): number[] {
  const pool = validValues.slice();
  function pluckValue() {
    const plucked = pool.splice(Math.floor(Math.random() * pool.length), 1);
    return plucked[0];
  }
  const points: number[] = [];
  while (pool.length > 0) {
    points.push(pluckValue());
  }

  return points;
}

function makeRandomTile(nullChance = 0) {
  return Math.random() >= nullChance
    ? {
        points: makePaths(),
        rotation: Math.floor(Math.random() * 4),
      }
    : null;
}

function initHand() {
  return {
    tiles: [makeRandomTile(), makeRandomTile()],
    active: 0,
  };
}

const GameStage = () => {
  const boardWidth = 3;
  const boardHeight = 4;

  const pathStart: [number, number] = [0, 0];

  const makeRandomTiles = (): TileState =>
    Array.from({ length: boardWidth * boardHeight }, () => makeRandomTile(0.5));

  const [tiles, setTiles] = useState<TileState>(
    Array.from({ length: boardWidth * boardHeight }, () => null)
  );
  const [playerHand, setPlayerHand] = useState<PlayerHand>(initHand());

  const handleActiveTileChange = (tileNum: number) => {
    setPlayerHand((prev) => ({ ...prev, active: tileNum }));
  };

  const handleActiveTileRotate = (delta: number) => {
    setPlayerHand((prev) => {
      return {
        ...prev,
        tiles: prev.tiles.map((tile, idx) => {
          if (idx !== prev.active || tile === null) return tile;
          return { ...tile, rotation: (tile.rotation + delta + 4) % 4 };
        }),
      };
    });
  };

  const handleActiveTilePlace = () => {
    const placedTile = playerHand.tiles[playerHand.active];
    setPlayerHand((prev) => {
      return {
        ...prev,
        tiles: prev.tiles.map((tile, idx) => {
          if (idx !== prev.active) return tile;
          return makeRandomTile();
        }),
      };
    });
    setTiles((prev) =>
      prev.map((t, i) => (i === nextStep.nextTileIdx ? placedTile : t))
    );
    // setHand(makeRandomTile());
  };

  function handleRandomize() {
    setTiles(makeRandomTiles());
  }

  function walkPath(entryTile: number, entryPoint: number): PathSegment[] {
    const segments: PathSegment[] = [
      { currentPath: null, nextTileIdx: entryTile, nextTileEntry: entryPoint },
    ];
    let failsafe = boardWidth * boardHeight * 4;

    let { nextTileIdx: tileIdx, nextTileEntry: tileEntry } = segments[0];

    while (tileIdx !== null && failsafe-- > 0) {
      let tile = tiles[tileIdx];
      if (tile === null) {
        // Have run out of tiles, this tile must be the next
        tileIdx = null;
        continue;
      }

      const rotatedEntry = tileEntry;
      const unrotatedEntry = (rotatedEntry - tile.rotation * 2 + 8) % 8;

      const pointIdx = tile.points.findIndex((p) => p === unrotatedEntry);
      const pointParity = pointIdx % 2;
      const nextPointIdx = pointParity === 0 ? pointIdx + 1 : pointIdx - 1;
      const unrotatedExit = tile.points[nextPointIdx];
      const rotatedExit = (unrotatedExit + tile.rotation * 2) % 8;

      const currentPath = {
        tileIdx: tileIdx,
        pathStart: unrotatedEntry,
        pathEnd: unrotatedExit,
      };

      let exitSide = Math.floor(rotatedExit / 2);
      let nextRow = Math.floor(tileIdx / boardWidth);
      let nextCol = tileIdx % boardWidth;

      if (exitSide === 0) {
        nextRow -= 1;
      } else if (exitSide === 1) {
        nextCol += 1;
      } else if (exitSide === 2) {
        nextRow += 1;
      } else if (exitSide === 3) {
        nextCol -= 1;
      }

      let nextTileIdx = null;
      if (
        nextRow >= 0 &&
        nextRow < boardHeight &&
        nextCol >= 0 &&
        nextCol < boardWidth
      ) {
        const idx = nextRow * boardWidth + nextCol;
        if (idx !== tileIdx) nextTileIdx = idx;
      }

      const exitParity = rotatedExit % 2;
      const nextTileEntry =
        exitParity === 0
          ? (rotatedExit + 4 + 1) % 8
          : (rotatedExit + 4 - 1) % 8;

      tileIdx = nextTileIdx;
      tileEntry = nextTileEntry;
      segments.push({ currentPath, nextTileIdx, nextTileEntry });
    }

    console.groupEnd();

    return segments;
  }

  const highlightedPath = walkPath(...pathStart);
  const nextStep = highlightedPath[highlightedPath.length - 1];


  function handleClear() {
    setTiles(Array.from({ length: boardWidth * boardHeight }, () => null));
    setPlayerHand(initHand());
  }

  function prepareTile(tile: TileData | null, idx: number): PipeTileDef | null {
    if (tile === null) return null;

    const paths: PipePathDef[] = [];
    const p = tile.points.slice();

    if (p.length === 0) {
      return null;
    }

    while (p.length > 1) {
      const s = p.shift();
      const e = p.shift();
      if (s === undefined || e === undefined) throw Error("Out of points!");

      const highlight = highlightedPath.some(
        (seg) =>
          (seg.currentPath !== null &&
            seg.currentPath.tileIdx === idx &&
            seg.currentPath.pathStart === s &&
            seg.currentPath.pathEnd === e) ||
          (seg.currentPath !== null &&
            seg.currentPath.tileIdx === idx &&
            seg.currentPath.pathStart === e &&
            seg.currentPath.pathEnd === s)
      );
      paths.push({ points: [s, e], highlight });
    }

    return { paths, rotation: tile.rotation };
  }
  const tileDefs: Array<PipeTileDef | null> = tiles.map(prepareTile);


  return (
    <>
      <StageContainer>
        <PipeBoard tileDefs={tileDefs} pathStart={pathStart} />
        <Hand>
          <button
            onClick={() => handleActiveTileRotate(-1)}
            style={{ fontSize: 30 }}
          >
            &#x27F2;
          </button>
          {playerHand.tiles.map((tile, num) => {
            const prepared = prepareTile(tile, -1);
            const isActive = playerHand.active === num;
            if (prepared === null) return null;
            return (
              <button
                onClick={
                  isActive
                    ? () => handleActiveTilePlace()
                    : () => handleActiveTileChange(num)
                }
                style={{ opacity: isActive ? 1 : 0.6 }}
              >
                <PipeTile tileDef={prepared} tileWidth={isActive ? 100 : 80} />
              </button>
            );
          })}
          <button
            onClick={() => handleActiveTileRotate(1)}
            style={{ fontSize: 30 }}
          >
            &#x27F3;
          </button>
        </Hand>
        <div
          style={{
            fontSize: 12,
            lineHeight: 1,
          }}
        >
          <button onClick={handleClear}>Reset</button>
          <button
            onClick={handleActiveTilePlace}
            disabled={nextStep.nextTileIdx === null}
          >
            Place next tile
          </button>
          <button onClick={handleRandomize}>Randomize</button>
        </div>
        {nextStep.nextTileIdx === null && (
          <PauseOverlay>
            <GameResults>
              <h1>Game Over!</h1>
              <p>
                Score:{" "}
                {highlightedPath.filter((s) => s.currentPath !== null).length}
              </p>

              <button onClick={handleClear}>New game</button>
            </GameResults>
          </PauseOverlay>
        )}
      </StageContainer>
      {process.env.NODE_ENV === "development" && (
        <Debug>
          {" "}
          Tiles:{" "}
          {JSON.stringify(
            tiles.map((t) =>
              t === null ? "null" : t.points.join(" ") + " r " + t.rotation
            ),
            null,
            2
          )}
          <br />
          <br />
          Highlighted Path ({highlightedPath.length - 1}):{" "}
          {JSON.stringify(highlightedPath, null, 2)}
        </Debug>
      )}
    </>
  );
};

const Hand = styled("div")`
  --col-bg-tile: hsla(270, 00%, 65%, 1);

  flex: 1 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export default GameStage;
