import { Global, css } from "@emotion/react";

const globalStyles = css`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
  }

  :root {
    --c-white: hsal(0, 0%, 100%, 1);
    --c-grey-90: hsla(0, 0%, 90%, 1);
    --c-grey-20: hsla(0, 0%, 20%, 1);

    --c-green: hsla(150, 60%, 50%, 1);
    --c-purple: hsla(270, 70%, 60%, 1);

    --col-bg-edge: var(--c-grey-90);
    --col-bg-tile: var(--c-purple);

    --col-edge-tile: var(--c-white);

    --col-path-stroke: var(--c-white);
    --col-path-outline: var(--c-white);
    --col-path-default: var(--c-grey-20);
    --col-path-active: var(--c-green);
  }
`;

function Theme() {
  return <Global styles={globalStyles} />;
}

export default Theme;
